.technology-chips {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.chip {
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    margin-bottom: 10px;
    display: inline-block;
}

.light .chip {
    background-color: #e9ecef;
    color: #495057;
}

.dark .chip {
    background-color: #34495e;
    color: #ecf0f1;
}

.chip:hover {
    background-color: #007bff;
    color: white;
    transform: translateY(-2px);
}

.dark-theme {
    background-color: #1a202c;
    color: #e2e8f0;
}

.light-theme {
    background-color: #f8f9fa;
    color: #2d3748;
}