@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$px-theme:			#3a86ff !default;
$px-dark:			#292929 !default;
$px-dark-1:			#f1f4ff !default;

$px-white:			#fff !default;
$px-black:			#000 !default;

$px-gray:			#f7f6ff !default;
$px-gray-2:			#e2e8f0 !default;

$px-body:			#555a64 !default;
$px-font-size:		15px !default;
$px-line-height:	1.7 !default;
$px-font:			'Inter', sans-serif !default;
$px-font-alt:		'Inter', sans-serif !default;

$px-shadow:			0 .375rem 1.5rem 0 rgba(140,152,164,.125) !default;
$px-shadow-sm:		0 12px 15px rgba(140,152,164,.1) !default;


// Breakpoints
$px-media-xsm:		567px !default;
$px-media-sm:		767px !default;
$px-media-md:		991px !default;
$px-media-lg:		1200px !default;