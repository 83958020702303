.blog-post {
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
}

.dark-bg-1 .blog-post {
    background-color: rgba(255, 255, 255, 0.1);
}

.light-bg-1 .blog-post {
    background-color: rgba(0, 0, 0, 0.05);
}

.blog-content {
    color: inherit;
}

.blog-content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.dark-bg-1 .blog-date {
    color: #ccc;
}

.light-bg-1 .blog-date {
    color: #666;
}

.blog-date {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.blog-thumbnail img {
    border-radius: 8px;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}